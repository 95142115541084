// @flow
import mediator from "@tvg/mediator";
import tracksFilterService from "@tvg/tracks-header/service";
import type { Dispatch } from "redux";
import type { UnaryFn } from "@tvg/types/Functional";
import type { UserData } from "../actions/login";
import { type Favorites, clearUserFavoriteTracks } from "../actions/favorites";
import { type UserActions, setUserPrefs } from "../actions/login";
import { removeTimeout } from "./userLocation";

const handleLogout = (
  user: UserData,
  manageEquibaseId: UnaryFn<boolean, void>,
  dispatch: Dispatch<Favorites | UserActions>
) => {
  // In case user logs out and greyhound filter is there, remove it
  const raceFilters = tracksFilterService.getUserRaceFilters();
  if (raceFilters.indexOf("G") > -1) {
    raceFilters.splice(raceFilters.indexOf("G"), 1);
    tracksFilterService.setRaceFilterPreference(raceFilters);
  }
  dispatch(clearUserFavoriteTracks());
  dispatch(setUserPrefs({}, false));
  // if user logs out should remove equibaseId
  manageEquibaseId(false);
  if (user.userLogout) {
    removeTimeout();
    // User triggered logout
    // Send event to iOS app when logout is successful
    mediator.ios.dispatch({
      type: "LOGOUT_SUCCESS"
    });
  }
};

export default handleLogout;

// @flow
import {
  // $FlowFixMe
  useReducer,
  // $FlowFixMe
  useEffect,
  // $FlowFixMe
  useState,
  // $FlowFixMe
  useRef,
  // $FlowFixMe
  useMemo
} from "react";

import { getDaysInMonth } from "date-fns";

import mediator from "@tvg/mediator";
import {
  LOGIN_ACTIVE_FLOWS,
  LOGIN_ACTIVE_FLOWS_STATUS
} from "@tvg/login-controller/src/reducers/modalReducer";
import type { LoginEmailRecoveryFormMessages } from "@tvg/types/Login";
import { generateEmailRecoverySchema } from "./validators";
import {
  type State,
  initialState,
  reducer
} from "../reducers/emailRecoveryReducer";

const ATTEMPTS_MAX = 3;

export function useEmailRecovery(messages: LoginEmailRecoveryFormMessages) {
  const formRef = useRef();
  const [isSSNVisible, setIsSSNVisible] = useState(false);
  const [state, dispatch] = useReducer<State>(reducer, initialState);
  const [startedBirthTyping, setStartedBirthTyping] = useState(
    state.values.dateOfBirth || false
  );
  const schema = useMemo(
    () => generateEmailRecoverySchema(messages),
    [messages]
  );

  // change modal title on max attempts reached
  useEffect(() => {
    if (state.attempts >= ATTEMPTS_MAX) {
      mediator.base.dispatch({
        type: "FORGOT_DETAILS_RECOVER_EMAIL_FAILED",
        payload: {
          message: "too many attempts"
        }
      });
      mediator.base.dispatch({
        type: "OPEN_LOGIN_FLOW",
        payload: {
          loginActiveFlow: LOGIN_ACTIVE_FLOWS["recover-email"],
          loginActiveFlowStatus: LOGIN_ACTIVE_FLOWS_STATUS.failure
        }
      });
    }
  }, [state.hasError, state.attempts]);

  // remove modal back action on success
  useEffect(() => {
    if (state.hasSentEmail) {
      mediator.base.dispatch({
        type: "FORGOT_DETAILS_RECOVER_EMAIL_SUBMIT_SUCCESS"
      });
      mediator.base.dispatch({
        type: "OPEN_LOGIN_FLOW",
        payload: {
          loginActiveFlow: LOGIN_ACTIVE_FLOWS["recover-email"],
          loginActiveFlowStatus: LOGIN_ACTIVE_FLOWS_STATUS.success
        }
      });
    }
  }, [state.hasSentEmail]);

  const isValid = useMemo(
    () => schema.isValidSync(state.values),
    [state.values]
  );

  return [
    { formRef, isSSNVisible, state, startedBirthTyping, isValid, schema },
    { setIsSSNVisible, dispatch, setStartedBirthTyping }
  ];
}

export function useDateOfBirth(defaultLimit: number = 31) {
  const [dayLimit, setDayLimit] = useState(defaultLimit);

  /*
   * ACC for validate the Month, Day and Year:
   * https://betfairus.atlassian.net/browse/FR-1281?atlOrigin=eyJpIjoiMGRhMmM3YzgwNWRjNGEzMWExOGUwZTMzOTZhNDgwZTgiLCJwIjoiaiJ9
   */
  const validateDay = (
    month: string,
    day: string,
    year: string,
    baseYear: string = new Date().getFullYear().toString()
  ) => {
    let validDay;
    const convertedMonth = Number(month);
    if (!isNaN(convertedMonth)) {
      const usedYear = Number(isNaN(year) ? baseYear : year);
      const lastDay = getDaysInMonth(new Date(usedYear, convertedMonth - 1));
      setDayLimit(lastDay);
      validDay = day > lastDay ? lastDay : day;
    }
    return { validDay };
  };

  const disablePredicate = (
    target: string,
    valueOptionItem: string,
    valueSelectItem: string,
    optionLength: number
  ) =>
    valueSelectItem === target &&
    optionLength > dayLimit &&
    valueOptionItem > dayLimit;

  return [validateDay, disablePredicate, setDayLimit];
}
